import React from "react"
import CongratsPage from "../components/onboarding/CongratsPage"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import dots from "../images/bgs/dots.svg"


const TeacherCongrats = () => (
  <Layout image={dots} full>
    <SEO title="Congratulations" />
    <CongratsPage
      congratsMessage="You are now a registered teacher on RemoteTA"
      teacher={true}
      buttonName="Start Posting Tasks"
    />
  </Layout>
)

export default TeacherCongrats
